.mobile-Only {
    display: none !important;
}

.tagsClick {
    cursor: pointer;
}

.watch-videoTag {
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: 0.014em !important;
    text-transform: uppercase !important;
    color: #d52b1d !important;
    border: 2px solid#d52b1d !important;
    padding: 2px 12px 2px 12px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 16px !important;
    box-shadow: none !important;
}

.watch-playlistTag {
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: 0.014em !important;
    text-transform: uppercase !important;
    color: #5b544e !important;
    border: 2px solid#5b544e !important;
    padding: 2px 12px 2px 12px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 16px !important;
    box-shadow: none !important;

}

.watch-podcastTag {
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: 0.014em !important;
    text-transform: uppercase !important;
    color: #8DC211 !important;
    border: 2px solid#8DC211 !important;
    padding: 2px 12px 2px 12px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 16px !important;
    box-shadow: none !important;

}


.watch-liveTag {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 15px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: 0.014em !important;
    text-transform: uppercase !important;
    color: #1138c2 !important;
    border: 2px solid#1138c2 !important;
    padding: 2px 12px 2px 12px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 16px !important;
    box-shadow: none !important;

}

/* .playkit-player .playkit-tooltip {
    margin-right: 15px !important;
} */

.minatureIcn {
    top: 20px;
    position: absolute;
    right: 25px;
    cursor: pointer;
    z-index: 11;
}

.content-playSec {
    margin: 10px 0 15px 0;
    width: auto !important;
    height: auto !important;
    position: relative;
}

.userActionSec {
    padding-left: 0px;
    text-decoration: none;
    list-style: none;
    float: right;
    margin-top: 0px;
}

.userActionSec li {
    display: inline-block;
    text-align: center;
    margin: 0 8px;
    font-size: 14px;
}

.userActionSec li img {
    display: block;
    margin: 0 auto;
    margin-bottom: 4px;
    cursor: pointer;
}

.text-right {
    text-align: right;
}

.watch-title {
    font-size: 22px;
    color: #82786F !important;
    font-weight: 400;
    box-shadow: none !important;
    margin-bottom: 15px;
    letter-spacing: -0.011em;
}

.black-triangle-title {
    font-size: 22px;
    color: black !important;
    font-weight: 400;
    box-shadow: none !important;
    margin-bottom: 15px;
    letter-spacing: -0.011em;
}


.watch-speaker {
    box-shadow: none !important;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 16px;
    color: #82786F !important;
    letter-spacing: -0.011em;
    font-family: 'DINPro-Medium' !important;
}

.watch-speaker a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.watch-speaker .speaker-name {
    cursor: pointer;
}

.watch-speaker .speaker-name:hover {
    text-decoration: underline;
}


.roundedImg img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.redirectWarningModal {
    width: 40% !important;
}

.watch-details {
    box-shadow: none !important;
    margin-bottom: 15px;
    font-size: 15px;
    color: #82786F !important;
    letter-spacing: -0.011em;
    font-family: 'DINPro-Light' !important;
}

.watch-desc {
    box-shadow: none !important;
    font-size: 14px;
    color: #82786F !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 48px;
}

.watch-desc-show {
    box-shadow: none !important;
    font-size: 14px;
    color: #82786F !important;
}

.content_watch_piinfo .pihoverlink {
    cursor: pointer;
}

.showmoreBtn {
    display: inline-block;
    background: transparent;
    border: none;
    color: #1592E6;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    font-family: 'DINPro-Medium' !important;
    padding: 0px;
    position: relative;
    top: -6px;
    /* margin-left: 3px; */
}

.watch-tags {
    color: #82786F !important;
    box-shadow: none !important;
    margin-bottom: 10px;
    font-size: 16px;
    /* border-bottom: 1px solid #82786F; */
    width: fit-content;
    border-radius: 0px !important;
    letter-spacing: -0.011em;

}

.watch-tags a {
    color: #82786F !important;
    box-shadow: none !important;
    margin-bottom: 10px;
    font-size: 16px;
    /* border-bottom: 1px solid #82786F; */
    text-decoration: underline;
    width: fit-content;
    border-radius: 0px !important;
    letter-spacing: -0.011em;

}

.black-triangle {
    color: black !important;
}

.watch-keywordList {
    padding-left: 0px;
    text-decoration: none;
    list-style: none;
    /* float:right; */
    margin-top: 20px;
}

.watch-keywordList li {
    width: fit-content;
    background: #E0DEDE;
    padding: 2px 2px;
    display: inline-block;
    text-align: center;
    margin: 0 3px 10px 0px;
    font-size: 12px;
    color: #82786F !important;
    /* text-transform: capitalize; */
    letter-spacing: -0.011em;
}

.watch-quizHd {
    font-weight: 600;
    font-size: 16px;
    box-shadow: none !important;
    color: #82786F !important;
    margin-bottom: 10px;
}

.watch-quizsubHd {
    font-weight: 400;
    font-size: 16px;
    box-shadow: none !important;
    color: #82786F !important;
    margin-bottom: 20px;
    letter-spacing: -0.011em;
    /* cursor: pointer; */
}

.watch-start-quiz {
    background: #82786F;
    color: #fff;
    padding: 8px 16px 10px 16px;
    border: 1px solid #82786F;
    margin-bottom: 10px;
    border-radius: 20px;
    font-size: 14px;
    letter-spacing: -0.011em;
    font-family: 'DINPro-Light' !important;
}

.watch-start-quiz:hover {
    background: #d52b1e;
    border: 1px solid #d52b1e;
    color: #fff;
    cursor: pointer;
}

.watch-addHd {
    box-shadow: none !important;
    font-size: 16px;
    margin: 20px 0 15px 0;
    color: #82786F !important;
    font-weight: 600;
    letter-spacing: -0.011em;

}

.watch-resourceSec {
    border: 1px solid #E0DEDE;
    min-height: 200px;
    width: 90%;
    margin-bottom: 20px;
}

.resource-cont::after {
    content: '';
    /* border: 0.1px solid #d52b1e; */
    background-image: url('../../public/img/Line-img.png');
    display: block;
    width: 40%;
    margin: 0 auto;
    margin-top: 10px;
    height: 01.1px;
}

.watch-resourceSec img {
    width: 100%;
}

.watch-resourceSec .resource-cont {
    box-shadow: none !important;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    padding-top: 8%;
    letter-spacing: -0.011em;
    word-break: break-word;
}

.up-next {
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: black;
    text-align: center;
}

.pdf-material {
    cursor: pointer;
}

.playlist_content-wrapper {
    /* margin-top: 10px; */
    background: #F5F5F5;
}

.inner-playlist-wrapper {
    height: 470px;
    overflow-x: hidden;
    overflow-y: auto;
}

.playlist-serial {
    text-align: center;
    padding: 40px 0;
}

.playlist-title {
    box-shadow: none !important;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.011em;
    background: #F5F5F5 !important;
    color: #82786F !important;
    font-family: 'DINPro-Light' !important;
    text-align: center;
}

.custom-playlist-title {
    margin-top: 10px;
}

.playlist-row {
    margin: 10px 0;
    cursor: pointer;
}

.playlist-row.activeContent {
    background: #DADADA;
}

/* .disabledInteraction .playlist-row:not(.playlist-row.activeContent ~ .playlist-row) {
    pointer-events: all;
}
.disabledInteraction .playlist-row.activeContent ~ .playlist-row{
    pointer-events: none;
} */

/* width */
.inner-playlist-wrapper::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.inner-playlist-wrapper::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.inner-playlist-wrapper::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 5px;
}

/* Handle on hover */
.inner-playlist-wrapper::-webkit-scrollbar-thumb:hover {
    background: #C1C1C1;
}


.shared-simg {
    height: 100% !important;
    object-fit: contain;
    width: 100%;
    display: block;
}

.shared-podcastTag-content {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: -0.011em !important;
    text-transform: uppercase !important;
    color: #8DC211 !important;
    border: 2px solid#8DC211 !important;
    padding: 1px 14px 2px 14px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 12px !important;
    float: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.shared-playlistTag-content {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: -0.011em !important;
    text-transform: uppercase !important;
    color: #5b544e !important;
    border: 2px solid#5b544e !important;
    padding: 1px 14px 2px 14px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 12px !important;
    float: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.shared-liveTag-content {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: -0.011em !important;
    text-transform: uppercase !important;
    color: #1138c2 !important;
    border: 2px solid#1138c2 !important;
    padding: 1px 14px 2px 14px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 12px !important;
    float: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.shared-videoTag-content {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    display: inline-block !important;
    align-items: center !important;
    letter-spacing: -0.011em !important;
    text-transform: uppercase !important;
    color: #d52b1d !important;
    border: 2px solid#d52b1d !important;
    padding: 1px 14px 2px 14px !important;
    margin: 0px 10px 0 0px !important;
    border-radius: 12px !important;
    float: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.shared-cont-title {
    margin: 6px 0;
    word-break: break-all;
    line-height: 1.2;

    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    line-clamp: 2; /* Limit to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 38px;
    white-space: normal;
}

.shared-cont-content {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 1.2;
    /* line-height: 14px !important; */
    /* letter-spacing: -0.011em !important; */
    color: #82786F !important;
    margin: 8px 10px 0 0px !important;
    box-shadow: none !important;
    text-align: left !important;
    display: block !important;
    /* white-space: nowrap; */
    /* width: 300px; */
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    line-clamp: 2; /* Limit to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 36px;
    white-space: normal;
}

.shared-cont-no-data {
    box-shadow: none !important;
    margin: 10px 0 0px 10px;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: -0.011em;
}

.shared-upName-content {
    font-weight: 500 !important;
    font-size: 13px;
    line-height: 1.2;
    color: #82786F !important;
    margin: 4px 10px 4px 0px !important;
    box-shadow: none !important;
    text-align: left;
    display: block !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    line-clamp: 2; /* Limit to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
    white-space: normal;
}

.shared-desc-content {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    /* letter-spacing: -0.011em !important; */
    color: #82786F !important;
    margin: 6px 4px !important;
    box-shadow: none !important;
    text-align: left;
    width: 70%;
}

.shared-sCover-content {
    position: relative;
    width: 100%;
}

.shared-sCoverInner-Content {
    display: block;
    background: rgba(0, 0, 0, .56);
    height: 100%;
    position: absolute !important;
    right: 0;
    text-align: center;
    width: 70px;
}

.shared-svgIcon-content-playlist {
    margin-top: 80%;
    width: 42px;
}

.shared-svgIcon-content,
.shared-svgIcon-content-video,
.shared-svgIcon-content-podcast {
    /* margin-top: 70%; */
    width: 20px;
    position: absolute;
    bottom: 15px;
    left: 25px;
}

.shared-sOuter {
    background: #fff !important;
    margin: 10px 10px 20px 10px !important;
    width: 95% !important;
}



.black-triangle-home {
    font-size: 15px;
    color: black !important;
    font-weight: 600;
    box-shadow: none !important;
    margin-bottom: 15px;
    letter-spacing: -0.011em;
}

.shared-topTag {
    box-shadow: none !important;
    margin: 10px 0 0px 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.011em;
    color: #82786F !important;
    font-family: 'DINPro-Light' !important;

}

.shared-topTag span {
    font-size: 16px;
    color: #D52B1D;
}

.shared-loadSvg {
    width: 25px;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}


@media (max-width:960px) {

    .shared-sOuter {
        width: 100% !important;
        margin: 0 !important;
    }

    .shared-desc-content {
        width: 100% !important;
    }

    .content-playSec {
        margin-left: -6.5% !important;
    }
}


@media (max-width:600px) {
    .shared-svgIcon-content {
        left: 32%;
    }

    .playlist-serial {
        padding-left: 4px;
    }

    .desktop-Only {
        display: none !important;
    }

    .mobile-Only {
        display: block !important;
    }

    .mobile-container {
        display: block !important;
    }

    .shared-sCover-content {
        width: unset;
        height: 100px !important;
    }

    .shared-simg {
        height: 100%;
        object-fit: fill;
    }

    .shared-topTag {
        margin: 20px 0 20px 0px;
    }

    .shared-desc-content {
        width: 100% !important;
    }

    .content-playSec {
        margin: 0 !important;
        width: 100vw !important;
        margin-left: -6.5% !important;
    }

    .content-playlistSec .enableInteraction {
        width: 100%
    }

    .playlist_content-wrapper {
        margin-top: 0 !important;
    }

    .content-metaSec {
        margin: 20px 0 0 0;
    }

    /* .custom-playlist-title{
        width: 366px !important;
    } */

    .custom-playlist-container {
        width: 100% !important;
        height: 135px !important;
    }

    .custom-shared-playlist-icon {
        /* min-height:70px !important; */
        margin-top: 60% !important;
    }

    .custom-shared-playlist-image {
        min-height: 100px !important;
    }

    .shared-cont-content {
        margin: 6px 4px 0 0 !important;
    }

    .content_embed {
        background-color: #09CA49;
        margin: 0 auto;
        width: 37%;
        padding: 3%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .posAbs {
        position: absolute;
    }

    .related-cover-content-play .shared-svgIcon-content-playlist,
    .svgIcon-content-playlist {
        margin-top: 80% !important;
    }

    .related-cover-content-thumbnail {
        height: 120px !important;
    }

    .related-cover-content img.related-content-image.simg-lg {
        min-height: 110px;
        min-width: 200px;
    }
}


@media (resolution:1.5dppx) {

    .custom-image-container {
        transform: translateY(3px);
    }

    .playlist-serial {
        padding: 27px 0;
    }

    /* .custom-shared-playlist-icon {
        width: 13px;
        bottom: 9px;
        left: 18px;
    } */

    .custom-shared-video-tag {
        line-height: 10px !important;
        padding: 2px 7px 2px 7px !important;
    }
}

@media (resolution:1.25dppx) or (resolution:1.1dppx) {
    .custom-image-container {
        transform: translateY(3px);
    }

    .playlist-serial {
        padding: 27px 0;
    }

    /* .shared-sCoverInner-Content {
        width: 38%;
        height: 93% !important;
    }

    .custom-shared-playlist-icon {
        width: 15px;
        bottom: 9px;
        left: 15px;
    } */

    .custom-shared-video-tag {
        line-height: 10px !important;
        padding: 1px 7px 2px 7px !important;
    }
}

/* @media (resolution:1.5dppx){
    .playlist-serial {
        padding-top: 85px !important;
    }
} */

.word-break {
    word-break: break-word;
}


@media (min-width: 1280px) {

    .shared-svgIcon-content-playlist {
        position: absolute;
        bottom: 15px;
        left: 15px;
    }
}
  
@media (min-width: 601px) and (max-width: 1279px) {
    .custom-shared-playlist-image {
        min-height: 100px !important;
    }

    .custom-shared-content {
        margin: 6px 6px 0 0px !important;
    }

    .playlist-serial {
        padding-left: 8px !important;
    }

    .custom-shared-playlist-icon {
        bottom: 18px;
        left: 20px;
    }

    .shared-sCoverInner-Content {
        width: 52px !important;
    }

    .shared-sCover-content {
        position: relative;
    }

    .shared-simg {
        /* height: 110px; */
        object-fit: fill;
    }

    .related-cover-content-thumbnail {
        height: 100% !important;
    }
}



.pi_tooltip {
    padding: 0 !important;
    text-transform: none !important;
    font-family: 'DINPro-Medium' !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    box-shadow: none !important;
    color: #82786F !important;
}

.pi_linkslist {
    text-align: left;
    font-size: 12px;
    padding: 0;
    word-break: break-all;
    margin-left: 15px;
}

.pi_linkslist li {
    list-style-type: circle !important;
    white-space: normal;
}

.pi_linkslist li a {
    color: #337ab7;
    text-decoration: none;
}

.MuiTooltip-tooltip {
    background-color: black !important;
}

.MuiTooltip-arrow {
    color: black !important;
}

@media (min-width:1182px) and (max-width:1370px) {
    .shared-sCover-content {
        width: 100%;
    }

    .shared-simg {
        height: 100%;
    }
}

.tagsClick span.black-triangle-title {
    font-size: unset;
}

.related-content-card {
    margin: 20px 0;
    max-width: 600px;
}

.related-content-container {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    width: 100%;
}

.related-cover-content {
    position: relative;
}

.related-cover-content-play {
    background: rgba(0, 0, 0, .56);
    height:99%;
    position: absolute !important;
    right: 0;
    text-align: center;
    width: 70px;
    z-index: 5;
}

.related-cover-content img.related-content-image.simg-lg {
    height: 100% !important;
    object-fit: fill;
}

.related-cover-content-thumbnail {
    height: 100%;
    position: relative;
}

.click_link {
    cursor: pointer;
}

.related-cover-content-thumbnail .videTime-search {
    bottom: 0px !important;
}

@media (max-width: 768px) and (min-width: 600px) {
    .related-cover-content-thumbnail {
        height: 125px;
        min-width: 120px;
    }
}

@media (min-width: 1050px) and (max-width: 1075px) {
    img.related-content-image.simg-lg {
        max-height: 130px !important;
    }
}



@media (min-width: 1250px) and (max-width: 1300px) {
    img.related-content-image.simg-lg {
        max-height: 158px !important;
    }
}



@media (min-width: 1301px) and (max-width: 1380px) {

    img.related-content-image.simg-lg {
        max-height: 169px !important;
    }

}



@media (min-width: 1381px) and (max-width: 1490px) {

    img.related-content-image.simg-lg {

        max-height: 180px !important;


    }

}

@media (min-width: 1491px) and (max-width: 1600px) {

    img.related-content-image.simg-lg {

        max-height: 195px !important;


    }

}

@media (min-width: 1601px) {

    img.related-content-image.simg-lg {
        max-height: 206px !important;
    }
}



/* Kaltura V7 mobile Styling changes */
/* if these styles cause an issue uncomment them in global.css */

/* @media (max-width: 768px) and (min-width: 300px) {
    .kaltura-player-container {
        height: 80%
    }
    .playkit-player.playkit-casting:not(.playkit-overlay-active) .playkit-bottom-bar,
    .playkit-player.playkit-metadata-loaded.playkit-hover:not(.playkit-overlay-active) .playkit-bottom-bar,
    .playkit-player.playkit-state-paused:not(.playkit-overlay-active) .playkit-bottom-bar,
    .playkit-player.playkit-state-idle:not(.playkit-overlay-active) .playkit-bottom-bar,
    .playkit-player.playkit-ad-break:not(.playkit-overlay-active) .playkit-bottom-bar,
    .playkit-player.playkit-menu-active:not(.playkit-overlay-active) .playkit-bottom-bar {
        padding: 5px 0px;
    }
} */

@media only screen and (max-width: 600px) {
    .related-cover-content-play {
        height: 120px !important;
    }
}
/* Kaltura V7 mobile Styling changes end */